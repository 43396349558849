import * as React from "react";
import Layout from "../../components/layout";
import Modal from "../../components/Modal";
import Seo from "../../components/seo";
import * as styles from "./contact.module.scss";
import PortableText from "react-portable-text";
import { graphql } from "gatsby";

import TextSemibold from "../../utils/text-semibold";
import TextMedium from "../../utils/text-medium";

export const query = graphql`
  query ContactQuery {
    allSanitySiteSettings {
      nodes {
        _rawKontakt
      }
    }
  }
`;

const ContactPage = ({ data }) => {
  return (
    <Modal one>
      <Seo title="Kontakt" />
      <div className={styles.Contact}>
        <PortableText
          content={data.allSanitySiteSettings.nodes[0]._rawKontakt}
          serializers={{
            semibold: TextSemibold,
            medium: TextMedium,
            bigtext: BigText,
          }}
        />
      </div>
    </Modal>
  );
};

const BigText = ({ children }) => <p className={styles.BigText}>{children}</p>;

ContactPage.Layout = Layout;

export default ContactPage;
